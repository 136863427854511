.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  font-family: 'Fredoka', sans-serif;
}
.App-header {
  background-color: #fbfff6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}
.App-header h1 {
  color: #000;
  position: relative;

  z-index: 1;
}

.App-header h1:before {
  width: 100%;
  height: 100%;
  border: 5px double #000;
  content: ' ';
  display: block;
  position: absolute;
  padding: 0.25em;
  left: -0.5em;
  top: -0.45em;
}
.App-header h1:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.25em;
  left: -0.08em;
  top: 0.075em;
  background: rgba(255,244,113,0.5);
  z-index: -1;
}
.App-header h1 > span {
  display: block;
  position: absolute;
  width: 1em;
  height: 0.5em;
  padding: 0.25em;
  right: -0.75em;
  top:  -0.75em;
  background: rgba(0, 132, 255, 0.5);
  z-index: -2;
}
.App-header img[alt="down"], .down img {
  width: 5vw;
  max-width: 100px;
  min-width: 80px;
}
.App-link {
  color: #61dafb;
}
.App-header a, .section a {
  color: #148dff;
}
#content1 {
  background: #dafdff;
  height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about {
  /*height: 100vh;*/
}
.section {
  position: relative;
  height: 100vh;
  float: left;
  width: 100vw;
}
.section .down {
  position: absolute;
  bottom: 2em;
  width: 100%;
  text-align: center;
}
.maxwidth {
  max-width: 768px;
  margin: 0 auto;
  padding: 2em;
}
.flexcon {
  display: flex;
}

.about .flexcon img {
  max-width: 100%;
}
.about .flexcon p {
  padding: 0 2em;
  text-align: left;
}